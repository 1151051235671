import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

import { RestComponent, DateUtils } from 'react-frontend-utils' 

import { Global } from '../models/Global'
import { ThemeColors } from '../Theme'
import { PatronEventsCalendar } from '../components/PatronEventsCalendar'
import { Typography, Button } from '@material-ui/core';
import { DateConversions } from '../utils/DateConversions';


/**
 * Page 1 of the Event signup process. Allows the user to select an Event. Uses the PatronEventsCalendar component.
 * 
 * The following props are required:
 *  - database: the database
 *  - nextPageCallback: a callback that is called when the user has selected an Event
 */
export class EventSignupPage1 extends RestComponent {


    _nextPageCallback;
    _calendarRef = React.createRef();
    _timezone = Global.getTimezone();
    _database;
    

    constructor(props) {
        super(props);
    
        this._nextPageCallback = props.nextPageCallback;  //callback when Booking slot is created
        this._database = props.database;
        this.state.selectedEvent = null;
    }
    

    //--------------------------------------------------------------------------------------------------------------------------
    //-------------------------------------------- CALENDAR CALLBACK -----------------------------------------------------------
    //--------------------------------------------------------------------------------------------------------------------------

    // Fetches a list of Events for the given date range for the service.
    _fetchSlots = (jsonDateStart, jsonDateEnd, successCallback, failureCallback) => {

        this.secureJSONFetch("/patron/events/" + this._database + "/slots?fromDate=" + jsonDateStart + "&toDate=" + jsonDateEnd, {}, 
                             (response) => successCallback(response), (error) => failureCallback(error)); 

    }


    _eventSelect = (event) => {
        console.log("Event selected: ", event);
        this.setState({selectedEvent: event});
    }

    
    render() {
       
        const isMobile = window.innerWidth < 660;

        if (this.state.isBusy)
            return this.getBusyComponent('center', {marginTop: 20});

        let eventName = "";
        let startStr = "";
        let spacesLeftStr = "";
        let spacesLeftVal = 0;
        if (this.state.selectedEvent) {
            eventName = "Selected Event: " + this.state.selectedEvent.title;
            startStr = DateConversions.dateTimeStr(this.state.selectedEvent.start);
            spacesLeftVal = this.state.selectedEvent.spacesLeft();
            spacesLeftStr = "Spaces Left: " + spacesLeftVal;
        }
    
        const canContinue = this.state.selectedEvent && spacesLeftVal > 0;

        const remainingMinutes = this.state.selectedEvent ? this.state.selectedEvent.remainingTimeMinutes() : 0;
        const remaininStr = remainingMinutes > 0 ? "Time Remaining to sign up: " + DateUtils.relativeTimeFriendlyFormat(remainingMinutes) : "";


        return (
            <Fragment>
                {this.getConfirmAlertComponent()}

                <Button disabled={!canContinue} variant='contained' onClick={() => this._nextPageCallback(this.state.selectedEvent)} 
                        style={{position: 'fixed', right: 20, zIndex: 10000, backgroundColor: canContinue ? 'green' : ThemeColors.veryLightGray, color: 'white'}}>Continue to Signup</Button>
                
  
                <div style={{display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: isMobile ? 0 : 20, alignItems: 'start', justifyContent: 'left', color: 'green', height: isMobile ? 50 : 30}}>
                    <Typography variant='body1' style={{fontWeight: 'bold'}}>{eventName}</Typography>
                    <Typography variant='body1' style={{fontWeight: 'bold'}}>{startStr}</Typography>
                </div>
                <Typography variant='h6' style={{fontWeight: 'bold', height: 30, color: spacesLeftVal > 0 ? 'blue' : 'red'}}>{spacesLeftStr}</Typography>
                <Typography variant='body1' style={{fontWeight: 'bold', height: 20, color: 'blue'}}>{remaininStr}</Typography>

                <div style={{marginLeft: 'auto', marginRight: 'auto', marginTop: 20, maxWidth: 1024}}>
                    <PatronEventsCalendar ref={this._calendarRef} 
                                            initialTimezone={this._timezone} 
                                            fetchSlots={this._fetchSlots}
                                            eventSelectCallback={this._eventSelect}
                                            loadingCallback={this._calendarBusy}
                                            />                
                </div>
            </Fragment>
        );
    }

}



export default withCookies(withRouter(EventSignupPage1));

