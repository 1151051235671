import { Slot }  from "./Slot";

import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import { Currency } from 'react-frontend-utils';

export class Patron {

    static Status = {
        MANUALLY_CONFIRMED:    {enumName: "MANUALLY_CONFIRMED", displayName: "Manually Confirmed", color: 'green'},
        CONFIRMED_FREE:        {enumName: "CONFIRMED_FREE", displayName: "Confirmed (Free)", color: 'green'},
        CONFIRMED_OTHER:       {enumName: "CONFIRMED_OTHER", displayName: "Confirmed (Other Payment)", color: 'green'},
        CONFIRMED_PAID:        {enumName: "CONFIRMED_PAID", displayName: "Confirmed (Paid)", color: 'green'},
        PAYMENT_PENDING:       {enumName: "PAYMENT_PENDING", displayName: "Reserved (Payment Pending)", color: 'orange'},
        CANCELLED:             {enumName: "CANCELLED", displayName: "Cancelled", color: 'red'}
    };

    id;
    patronCount;
    patronNames;
    patronEmail;
    patronPhone;
    enableSms;
    additionalData;
    optionalCosts;
    calculatedCost;
    isoCurrency;

    patronBooked;
    paymentIntent;
    paidOn;
    refundedAmount;
    isCashPayment;

    membershipInfo;

    cancelled;      // only used for Event-Signup, not Bookings. For Bookings, the booking is cancelled

    constructor(json) {

        if (json) {
            this.id = json.id;  
            this.patronCount = json.patronCount;
            this.patronNames = json.patronNames;
            this.patronEmail = json.patronEmail;
            this.patronPhone = json.patronPhone;
            this.enableSms = json.enableSms;
            this.additionalData = json.additionalData;
            this.optionalCosts = json.optionalCosts;
            this.calculatedCost = json.calculatedCost;
            this.isoCurrency = json.isoCurrency;
            this.patronBooked = json.patronBooked;
            this.paymentIntent = json.paymentIntent;
            this.paidOn = json.paidOn;
            this.refundedAmount = json.refundedAmount;
            this.isCashPayment = json.isCashPayment;
            this.membershipInfo = json.membershipInfo;
            this.cancelled = json.cancelled;
        }
        else 
            this.id = "pt_" + Slot.randomId();  // new Patron

    }

    copy() {
        const copy = new Patron();
        copy.id = this.id;
        copy.patronCount = this.patronCount;
        copy.patronNames = this.patronNames;
        copy.patronEmail = this.patronEmail;
        copy.patronPhone = this.patronPhone;
        copy.enableSms = this.enableSms;
        copy.additionalData = this.additionalData;
        copy.optionalCosts = this.optionalCosts;
        copy.calculatedCost = this.calculatedCost;
        copy.isoCurrency = this.isoCurrency;
        copy.patronBooked = this.patronBooked;
        copy.paymentIntent = this.paymentIntent;
        copy.paidOn = this.paidOn;
        copy.refundedAmount = this.refundedAmount;
        copy.isCashPayment = this.isCashPayment;
        copy.membershipInfo = this.membershipInfo;
        copy.cancelled = this.cancelled;
        return copy;
    }

    clear() {
        this.patronCount = 0;
        this.patronNames = [];
        this.patronEmail = null;
        this.patronPhone = null;
        this.enableSms = false;
        this.additionalData = null;
        this.optionalCosts = [];
        this.calculatedCost = 0;
        this.isoCurrency = null;
        this.patronBooked = null;
        this.paymentIntent = null;
        this.paidOn = null;
        this.refundedAmount = null;
        this.isCashPayment = false;
        this.membershipInfo = null;
        this.cancelled = false;
    }


    status() {

        if (this.cancelled)
            return Patron.Status.CANCELLED;     // only for Event Signup!

        if (!this.patronBooked)
            return Patron.Status.MANUALLY_CONFIRMED;
        if (this.calculatedCost === 0)
            return Patron.Status.CONFIRMED_FREE;
        if (this.isCashPayment)
            return Patron.Status.CONFIRMED_OTHER;
        if (this.paymentIntent && this.paidOn)
            return Patron.Status.CONFIRMED_PAID;

        return Patron.Status.PAYMENT_PENDING;
    }

    refundable() {
        return this.paymentIntent && this.calculatedCost > 0 && this.paidOn && this.refundedAmount < this.calculatedCost;
    }

    isManual() {
        return !this.patronBooked;
    }

    getPrimaryPatron() {
        return this.patronNames[0];
    }


    details() {
        // Get a comma seperated list of Patrons, ignoring blank names or those with only whitespace
        let names = "";
        if (this.patronNames)
            names = this.patronNames.filter(name => name && name.trim()).join(", ");

        // Get a count of the blank names
        let blankCount = 0;
        if (this.patronNames)
            blankCount = this.patronNames.filter(name => !name || !name.trim()).length;

        const blankText = blankCount > 0 ? " (+" + blankCount + " unnamed)" : "";
        
        // Additional purchases:
        let additional;
        if (this.optionalCosts)
            additional = this.optionalCosts.join(", ");


        return (
            "<div>" +
                (this.patronEmail ? ("<div>Contact Email: " + this.patronEmail + "</div>") : "") +
                (this.patronPhone ? ("<div>Contact Phone: " + this.patronPhone + (this.enableSms ? " (SMS)" : "") + "</div>") : "") +
                (names ? ("<div>Patrons: " + names + blankText + "</div>") : "") +
                (this.membershipInfo ? ("<div>Membership: " + this.membershipInfo + "</div>") : "") +
                (additional ? ("<div>Additional Purchases: " + additional + "</div>") : "") +
                (this.additionalData ? ("<div>Additional Info: " + this.additionalData + "</div>") : "") +
            "</div>"
        );
    }

    paymentBadge() {
        let bookingPaid;
        let tooltip;


        const calculatedCost = Currency.round(this.calculatedCost);
        const refundedAmount = this.refundedAmount > 0 ? Currency.round(this.refundedAmount) : 0;


        if (this.isCashPayment || (this.paymentIntent && this.paidOn)) {        // cash payment or paid
            if (this.isCashPayment) {
                bookingPaid = {background: 'yellow', textColor: 'black', text: 'OTHER PAYMENT DUE'};
                tooltip = "Due from Patron: " +  calculatedCost + " " + this.isoCurrency;
            }
            else if (refundedAmount > 0) {
                console.log("Refunded amount: ", refundedAmount, " calculated cost: ", calculatedCost);
                bookingPaid = {background: 'cyan', textColor: 'black', text: refundedAmount === calculatedCost ? 'REFUNDED' : 'PARTIALLY REFUNDED'};
                tooltip = "Paid: " + calculatedCost + ", Refunded: " + refundedAmount + " " + this.isoCurrency;
            }
            else {
                bookingPaid = {background: 'green', textColor: 'white', text: 'PAID'};
                tooltip = "Paid: " + calculatedCost + " " + this.isoCurrency
            }   
        
            return <Tooltip title={tooltip}>
                        <div style={{backgroundColor: bookingPaid.background, color: bookingPaid.textColor, padding: 2, borderRadius: 2, textAlign: 'center'}}>{bookingPaid.text}</div>
                    </Tooltip>;
        }
        return null;        // no badge
    }


}
